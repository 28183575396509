import * as React from 'react';

import ROUTES from 'platform/constants/routes';
import Settings from 'platform/services/settings';
import {byRoute} from 'platform/decorators/routes';
import HelperComponent from 'platform/classes/helper-component';
import BasketController from 'platform/api/basket';
import {
    IBasketListResponseModel,
    IBasketResponseModel,
    IBasketChangeResponseModel
} from 'platform/api/basket/models/response';
import EmptyState from 'components/empty-state';
import {formatPrice} from 'platform/services/helper';
import Connection from 'platform/services/connection';
import DispatcherChannels from 'platform/constants/dispatcher-channels';
import Storage from 'platform/services/storage';
import PageLoader from 'components/page-loader';
import {IResponse} from 'platform/constants/interfaces';
import ConfirmModal from 'components/confirm-modal';

import './style.scss';
import PhotoStorage from 'platform/services/photoStorage';
import {PriceNotEnoughModal} from './components/priceNotEnoughModal';
import {Shared} from 'modules';
import Slider from "react-slick";

import {IProductListResponseModel} from "../../../platform/api/product/models/response";
import ProductController from "../../../platform/api/product";
import Environment from "../../../platform/services/environment";
import MasterCard from "../../../assets/images/master.svg";
import GtagData from "../../../platform/services/gtagData";
import {responseEnum} from "../checkout";
import VisaCard from "../../../assets/images/visaImage.svg";
import OrderController from "../../../platform/api/order";
import {IOrderResultResponseModel} from "../../../platform/api/order/models/response";
import ListItem from '../home/components/discounted-products/components/list-item';
import { AddressIconSvg, SVGComponentNextArrowIconList, SVGComponentPrevArrowIconList } from 'components/svg-components';
import ShimmerLoading from 'components/loader-content/shimmerLoading';
import EmptyStateBasket from 'components/empty-state/basket';
import OutOfStockPopUp from 'components/empty-state/basket/outOfStockPopUp';
import { generateAddress } from './utils';
import AllProductsList, { PageTypeEnum, PlacementEnum } from '../home/components/all-products';

interface IState {
    allTotalprice:number;
    data?: IBasketResponseModel;
    outOfStockConfirm: boolean;
    priceNotEnoughModalOpen: boolean;
    dataSlider: IProductListResponseModel[];
    resultInfo: IOrderResultResponseModel;
    PriceInfoBasket:any
};

@byRoute(ROUTES.CART)
class Cart extends HelperComponent<{}, IState> {

    public state: IState = {
        allTotalprice:0,
        outOfStockConfirm: false,
        priceNotEnoughModalOpen: false,
        dataSlider: [],
        resultInfo: {
            totalPrice: 0,
            deliveryFee: 0,
            receivedBonus: 0,
            totalDiscountedPrice: 0,
            receivedBonusWithoutPromo: 0,
            isBonus: 0,
            isBonusVisa: 0,
            multiply: 0,
            totalDiscountedPriceWithoutPromo:0,
            totalPriceWithoutPromo: 0
        },
        PriceInfoBasket:        {
            totalBonus: 0,
            totalPrice: 0,
            totalDiscountedPrice: 0,
            priceWithoutPercent:0,
            isBonus: 0,
            multiply: 0
        }


    };
    
    public settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        swipeToSlide: true,
        slidesToShow: 5,
        fade: false,
        slidesToScroll: 1,
        nextArrow: <div><SVGComponentPrevArrowIconList /></div>,
        prevArrow: <div><SVGComponentNextArrowIconList /></div>,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 766,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    public componentDidMount() {
        this.fetchData();
        this.getTotalPrice();
        this.updateBasketCount();
        this.fetchDataSlider();
        window.addEventListener(DispatcherChannels.CartUpdate, this.fetchData);
        window.addEventListener(DispatcherChannels.CartUpdate, this.getTotalPrice);
    }

    private deleteBasketItem = async (item: IBasketListResponseModel) => {
        const result = await BasketController.Delete(item.productId, item.isPackage);
        
        if (result.success) {
            this.updateBasketCount();
            // this.getBasketCount();
            this.updateAllProducts()

            this.getTotalPrice();
            GtagData(item,"remove_from_cart")
            this.fetchData();
        }
    };

    private getTotalPrice = async () => {
        const modifyResult:any = await BasketController.GetPrice();
        if(modifyResult.success) {
            this.safeSetState({
                PriceInfoBasket: modifyResult.data,
            });
        }
    };

    private updateBasketCount = () => window.dispatchEvent(new CustomEvent(DispatcherChannels.CartItemsUpdate));
    private updateBasket = () => window.dispatchEvent(new CustomEvent(DispatcherChannels.CartUpdate));
    private updateAllProducts = () => window.dispatchEvent(new CustomEvent(DispatcherChannels.AlllProductUpdate));


    private fetchDataSlider = async () => {
        const body = {
            productText: '',
            categoryIds: [Environment.RecommendedProductsId],
            brandIds: [],
            producerIds: [],
            activeIngredientIds: [],
            sortBy: undefined,
            hasDiscount: false,
            pageSize:30,
            pageNumber:1,
            paging: {
                count: 30,
                page: 1
            }
        };
        const result = await ProductController.GetList(body);
        this.safeSetState({dataSlider: result && result.data && result.data.list || []});
    }

    private fetchData = async () => {
        const result = await BasketController.GetList();
        if(result.success) {
            this.safeSetState({data:{ ...this.state.data, ...result.data }});
        }
    };

    private changeCount = async (index: number, count: number) => {
        const {data} = this.state;
        let modifyResult: IResponse<IBasketChangeResponseModel>;
        if (data) {
            if (count) {
                Connection.AbortAll();
                modifyResult = await BasketController.Change({
                    productId: data.items[index].productId,
                    productQuantity: count,
                    isPackage: data.items[index].isPackage
                });
                data.items[index].productQuantity = count;
                this.fetchData();
            } else {
                modifyResult = await BasketController.Delete(data.items[index].productId, data.items[index].isPackage);
                data && data.items.splice(index, 1);
                this.updateBasketCount();
            }

            
            if (modifyResult.success) {
                await this.getTotalPrice(); 
                window.dispatchEvent(new CustomEvent(DispatcherChannels.CartItemsUpdate));
            }
        }
    };

    private goToCheckout = async () => {
        const {data,PriceInfoBasket} = this.state;  

        if(!Storage?.currentAddress?.addressText) {
            window.dispatchEvent(new Event(DispatcherChannels.openAddress))
        } else if (data) {
            const price = (PriceInfoBasket.totalDiscountedPrice || PriceInfoBasket?.totalPrice);
            if (data?.items?.some(item => !item.stockQuantity) || PriceInfoBasket.isNotAllowed) {
                this.safeSetState({outOfStockConfirm: true});
            }
            else if (price < PriceInfoBasket.minAmount) {
                this.safeSetState({priceNotEnoughModalOpen: true})
            } else {
                window.routerHistory.push(`${ROUTES.CHECKOUT}?total=${price}`)
                GtagData(data,"begin_checkout")
            };
        }
    };
    
    private closeOutOfStockConfirm = () => this.safeSetState({outOfStockConfirm: false});

    private deleteAll = async () => {
        const result = await BasketController.DeleteAll();
        if(result?.success) {
            this.safeSetState({data: {}});
            window.dispatchEvent(new CustomEvent(DispatcherChannels.CartItemsUpdate));
            this.updateAllProducts()
        }
    };

    private togglePriceNotEnoughModal = () => {
        const {priceNotEnoughModalOpen} = this.state;
        this.safeSetState({priceNotEnoughModalOpen: !priceNotEnoughModalOpen});
    };

    private chnageAddress = () => {
        window.dispatchEvent(new Event(DispatcherChannels.openAddress))
    };

    public render() {
        const {data, PriceInfoBasket, dataSlider, priceNotEnoughModalOpen, outOfStockConfirm} = this.state;
        if(!data) return (<ShimmerLoading childColCount={3} colCount={2} MClass={"basketPageShimmer"} className={"basketChildShimmer"}/>)

        return (
            <div className='basket_containner'>

                {data?.items && data?.items?.length !== 0 ? 
                    <section className="G-page P-cart-page">
                        <div className="P-cart-left-box">
                            <div className="G-flex G-flex-justify-between G-flex-align-center G-full-width P-basket-clear">
                                <div id='basketTitle' className='basket_name_count'>
                                    <h1 className="G-fs-26 G-full-width">{Settings.translations.cart}</h1>
                                    {!!data?.items?.length && <span className='count_basket'>{`(${data?.items?.length > 99 ? '99+' : data?.items?.length})`}</span>}
                                </div>
                                <p className="G-ml-auto P-clear-all" onClick={this.deleteAll}>{Settings.translations.clear_basket}</p>
                            </div>
                                    
                            <div className="G-flex G-flex-justify-between P-cart-block">
                                <div className="G-flex G-flex-column P-basket-products">
                                    <Shared.Products.BasketList
                                        onQuantityChange={this.changeCount}
                                        onDeleteBasketItem={this.deleteBasketItem}
                                        data={data?.items}
                                    />
                                </div>
                            </div>

                            {priceNotEnoughModalOpen &&
                                <PriceNotEnoughModal amount={PriceInfoBasket.minAmount} onClose={this.togglePriceNotEnoughModal}/>}
                            {outOfStockConfirm && <OutOfStockPopUp  onClose={this.closeOutOfStockConfirm}/>}
                        </div>
                        <div className="P-cart-right-box">
                            {!!Storage?.currentAddress?.addressText && 
                            <div className="P-data-block-wrapper addressBasketBlock">
                                <div className='AddressLine'>
                                    <p className='address'>{Settings.translations.Delivery_address}</p>
                                    <button className='addressChange' onClick={this.chnageAddress}>{Settings.translations.edit_text}</button>
                                </div>
                                <div className='addressAndIcon'>
                                    <span className='addressIcon'><AddressIconSvg/></span>
                                    <p className='addressValue'>
                                        {`${Storage?.currentAddress?.addressText}, `}
                                        {generateAddress()}
                                    </p>               
                                </div>
                            </div>}
                            
                            {PriceInfoBasket?.totalPrice ?
                                <div className="P-data-block-wrapper">
                                        <div className="P-data-block G-flex-column">
                                            <div className="collected_bonus G-flex G-flex-justify-between G-flex-align-center G-mb-25">
                                                <span className="G-fs-normal G-clr-main G-text-bold">{Settings.translations.accumulative_bonus}</span>
                                                <h1 className="G-clr-main G-text-bold G-fs-normal G-mt-5">{PriceInfoBasket.totalBonus} {Settings.translations.pointss}</h1>
                                            </div>
                                        </div>
                                        <div className="total_basket G-flex G-flex-justify-between G-flex-align-center">
                                            <span className="label">{Settings.translations.total}</span>
                                            <span className="value">{formatPrice(PriceInfoBasket.totalDiscountedPrice)}</span>
                                        </div>
                                        
                                        <button id="order_process_start_btn"
                                            className="G-mt-50 G-main-button G-ml-auto G-fs-normal P-pay-button"
                                            onClick={this.goToCheckout}
                                        >{Settings.translations.next_step}</button>
                                </div> : <ShimmerLoading childColCount={1} colCount={1} MClass={"basketPageShimmerCard"} className={"basketChildShimmerCard"}/>
                            }
                        </div>
                    </section> : <EmptyStateBasket />}

                {dataSlider.length ? <section className="G-page P-home-discounted-products respon cart-slider">
                    <h2 className="G-page-title G-clr-orange ">{Settings.translations.suggested_products}</h2>
                    <div className="P-list-wrapper">
                        <Slider
                            {...this.settings}
                            arrows={true}
                            swipe={true}
                            autoplay={false}
                            autoplaySpeed={3000}
                        >
                            {dataSlider.map(item => <div key={item.id}>
                                <ListItem data={item}/>
                            </div>)}
                        </Slider>
                    </div>
                </section> : null}

                <AllProductsList params={{placementType:PlacementEnum.Pages,pageId:PageTypeEnum.Basket}} handleUpdate={() => this.updateBasket()}/>
            </div>
        );
    }
}

export default Cart;
