import * as React from 'react';
import {Shared} from 'modules';
import ROUTES from 'platform/constants/routes';
import {byRoute} from 'platform/decorators/routes';
import {IProductListResponseModel} from 'platform/api/product/models/response';
import {buildFilters} from './services/helper';
import Filter from './components/filter';
import Connection from 'platform/services/connection';
import SortBox from './components/sort-box';
import HelperComponent from 'platform/classes/helper-component';
import Storage from "../../../../../platform/services/storage";
import {ICategoryTreeResponseModel} from "../../../../../platform/api/category/models/response";
import {IProductFilterRequestModel} from "../../../../../platform/api/product/models/request";
import ScrollPagination from 'components/pagination/scrollPagination';
import ScrollPageLoader from 'components/page-loader/scrollLoader';
import EmptyState from 'components/empty-state';
import { getfromListPageNumber, getIsRestoreKey, getScrollY, removefromListPageNumber, removeIsRestoreKey, removeScrollY, sendIsRestoreKey } from 'platform/hooks/useStorage';
import DispatcherChannels from 'platform/constants/dispatcher-channels';
import environment from 'platform/services/environment';
import './style.scss';
import GlobalStorageData from 'platform/services/search';
import { PromoCollectionCover } from './components/brandPage/promo_cover';
import { BrendCover } from './components/brandPage/brand_cover';
import AllProductsList, { PageTypeEnum, PlacementEnum } from 'modules/pages/home/components/all-products';
import Settings from 'platform/services/settings';
import Sidebar from 'modules/pages/home/components/all-products/components/suggestedItem';
import ProductController from 'platform/api/product';
import { withRouter } from 'react-router-dom';
import EmptyStateBasket from 'components/empty-state/basket';
import EmptyStateProduct from 'components/empty-state/product';
const pageChangeListener = 'productlistpage';

enum PageSizeEnum {
    size = 20
}

export enum CategoryPageTypeEnum {
    brandPage = 1
}

interface IProps {
    onChange(): void;
    initialLoading:any;
};

interface IState {
    loading: boolean;
    sortShow: boolean;
    total: null;
    preferredProductId: number | null,
    data?: IProductListResponseModel[];
    specialProducts?: IProductListResponseModel[];
    categoryTreeData: ICategoryTreeResponseModel[];
    body: IProductFilterRequestModel;
    prevCategoryIdRef:any;
    showMobileFilter:boolean;
    pageLimitSize:number;
    siderBarData:null;
    currentItemId:null;
};

@byRoute([ROUTES.PRODUCTS.MAIN])
class List extends HelperComponent<IProps, IState> {
    constructor(props:any) {
        super(props);
        this.fetchDebounceData = this.debounce(this.fetchDebounceData.bind(this), 300);
    }

    public state: any = {
        loading: false,
        total: null,
        preferredProductId: null,
        categoryTreeData: [],
        sortShow: false,
        body: {
            brandIds: [],
            producerIds: [],
            categoryIds: [],
            activeIngredientIds: [],
        },
        AllData:{
            // brandData: [],
            // producerData: [],
            // categoryData: [],
            // activeIngredientData: [],
            productsData:[]
        },
        showMobileFilter:false,
        pageLimitSize:PageSizeEnum.size,
        siderBarData:null,
        currentItemId:null,
        categoryTitle: null,
    };

    public componentDidMount() {
        const positionY:any = getScrollY()
        if(positionY) {
            setTimeout(() => {
                window.scrollTo({top: positionY, left: 0});
                removeScrollY()
            },600)
        }


        // Check screen width and apply conditional logic based on breakpoints
        const screenWidth = window.innerWidth;

        if (screenWidth <= 520) {
            this.setState({pageLimitSize:20})
        } else if (screenWidth <= 700) {
            this.setState({pageLimitSize:18})
        } 
        window.addEventListener(DispatcherChannels.refetchNewCategoryData, this.eventHandler);
    }

    public componentWillUnmount() {
        window.removeEventListener(DispatcherChannels.refetchNewCategoryData, this.eventHandler);
    }

    private eventHandler = () => {
        sendIsRestoreKey()
        this.fetchDebounceData(1)
    };

    private debounce<T extends (...args: any[]) => void>(func: T, wait: number): (...args: Parameters<T>) => void {
        let timeout: ReturnType<typeof setTimeout>;
        return function (this: any, ...args: Parameters<T>) {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(context, args), wait);
        };
    }

    private filterChange = () => {
        this.safeSetState({data: []});
        window.dispatchEvent(new CustomEvent(pageChangeListener, {detail: 1}));
    }

    private fetchDebounceData = (pageNumber: any): any => this.fetchData(pageNumber);
    private abortController: AbortController | null = null;

    private fetchData = async (pageNumber: number) => {
        if (this.abortController) {
            this.abortController.abort();
        }
    
        this.abortController = new AbortController();
        const abortsignal = this.abortController.signal;
        const query = new URLSearchParams(window.location.search);


        try {
            this.safeSetState({loading: true});
            const newBody = {...buildFilters()}
            delete newBody.CategoryPageType
            const body = {
                ...newBody,
                pageNumber,
                pageSize: this.state.pageLimitSize,
                paging: {
                    count: this.state.pageLimitSize,
                    page:pageNumber 
                }
            };
    
            const HEADERS = Connection.createHeaders();
            const response = await fetch(`${environment.BASE_URL}api/product/list`, {
                method: 'POST',
                headers: HEADERS,
                body: JSON.stringify(body),
                signal: abortsignal 
            });
    
            const result = await response.json();
            const preferredProductId = query.get('preferredProductId');
            const stateData: any = this.state.AllData.productsData;
    
            if (!result.aborted && result.data) {
                const data = getIsRestoreKey() ? result.data.products.data : stateData.concat(result.data.products.data);
                const uniqueMap = new Map();
                
                data.forEach((item: any) => { uniqueMap.set(item.id, item) });
                let uniqueArray = Array.from(uniqueMap.values());
                const pageNumberFromDetail = getfromListPageNumber()

                if(!pageNumberFromDetail) {
                    GlobalStorageData.fillProductList(uniqueArray)
                } 

                if(pageNumberFromDetail) {
                    uniqueArray = GlobalStorageData.productList
                    removefromListPageNumber()
                }

                this.safeSetState({
                    AllData: {
                        productsData: uniqueArray,
                        pageCount: result.data.products.pageCount,
                    },
                    data: stateData.concat(result.data.data),
                    total: result.data.products.itemCount,
                    preferredProductId,
                    loading: false
                });
            }
            
            removeIsRestoreKey();
            return result.data;
    
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Fetch aborted');
            } else {
                console.error('Fetch error:', error);
            }
            this.safeSetState({loading: false});
            return null;
        }
    };

    private openCloseFilter = () => {
        const { showMobileFilter } = this.state;
        if(showMobileFilter) {
            document.body.style.overflow = 'initial';
        } else {
            document.body.style.overflow = 'hidden';
        }
        this.setState({showMobileFilter:!showMobileFilter})
    }

    public async handleOpenSideBar(id?:number) {
        const { currentItemId } = this.state
        const result:any = await ProductController.GetProductsList({placementType:PlacementEnum.SuggestedProducts,pageId:id || currentItemId})
        if(result?.data?.length) { this.setState({siderBarData:result?.data,currentItemId:id || currentItemId}) }
    }

    public render() {
        const { total, loading, preferredProductId, AllData, showMobileFilter, siderBarData,categoryTitle } = this.state;
        const { productsData, pageCount} = AllData
        const query = new URLSearchParams(window.location.search);
        const categoryId = query.get('categoryIds');
        const CategoryPageType = query.get('CategoryPageType');
        const CollectionId = query.get('CollectionId');
        const lang:any = Settings.language


        return (
            <div className='productList'>
                <PromoCollectionCover CollectionId={CollectionId} callTitle={(title:string) => {this.safeSetState({categoryTitle:title})}} itemCount={total}/>
                <BrendCover CategoryPageType={CategoryPageType} callTitle={(title:string) => {this.safeSetState({categoryTitle:title})}} itemCount={total}/>
                <section className="G-page P-products-list-page">
                    <Filter openFilter={this.openCloseFilter} showMobileFilter={showMobileFilter} FilterData={AllData} onChange={this.filterChange} productLoading={loading}  />
                    <div className="P-list-wrapper newWraper">
                        {categoryId && <AllProductsList className={'promotionStyle'} params={{placementType:PlacementEnum.Categories,pageId:buildFilters()?.categoryIds[0]}}/>}
                        <SortBox openFilter={this.openCloseFilter} onChange={this.filterChange} AllData={AllData} categoryTitleOutside={categoryTitle} itemCount={total}/>
                        
                        {<div className='newWraper_items'>
                            {productsData?.map((item:any,index:number) => <Shared.Products.SimilarListItem 
                                handleOpenSideBar={(id:number) => this.handleOpenSideBar(id)} 
                                key={index} 
                                specialProductId={preferredProductId} 
                                data={item} 
                                fromList={true}
                            />)}
                        </div>}
                        
                        <ScrollPagination<IProductListResponseModel> 
                            classNameList="P-product-page" 
                            pageChangeListener={pageChangeListener}
                            fetchData={this.fetchDebounceData}
                            loading={loading}
                            pageCount={pageCount}
                        />
                        
                        {loading ? <ScrollPageLoader /> : total === 0 && <EmptyStateProduct />}
                        {/* <ScrollPageLoader /> */}
                    </div>
                    <Sidebar
                        lang={lang}
                        siderBarData={siderBarData} 
                        onClose={() => this.setState({siderBarData:null})} 
                        handleUpdate={(id:number) => this.handleOpenSideBar()}
                    /> 
                </section>
            </div>
        );
    }
};
export default withRouter(List);