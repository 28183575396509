
import React from "react";
import "./style.scss"
import { useState } from "react";

const TipSelection = ({ onTipSelect,tipToDriver }) => {

    const handleTipSelection = (tip:number) => {
        onTipSelect(tip);
    };

    return (
        <div className="tip-options">
            {[0, 3, 5, 7, 10].map((tip) => (
                <div 
                    key={tip} 
                    className={`tip-button ${tipToDriver === tip ? "active" : ""}`} 
                    onClick={() => handleTipSelection(tip)}
                >
                    {tip}%
                </div>
            ))}
        </div>
    );
};

export default TipSelection;
