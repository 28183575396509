import { DiscountImg, Hour24Img } from 'components/svg-components';
import BrandController from 'platform/api/brand';
import ROUTES from 'platform/constants/routes';
import Settings from 'platform/services/settings';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import PopupHeader from './promoPopUp';
import { ProductSortEnum } from 'platform/api/product/constants/enums';
import ShimmerLoading from 'components/loader-content/shimmerLoading';

export enum CollectionPromo {
  Promo,
  Discount,
  Collection
}



const HeaderPromoLink = () => {
  const [promoCollections, setData] = useState<any>(null); // Assuming type 'IState['data']'
  const [promoListHeader, setPromoListHeader] = useState<any>(10); // Assuming type 'IState['data']'
  const [CollectionId, setCollectionIdr] = useState<any>(10); // Assuming type 'IState['data']'
  const [showViewAll, setShowViewAll] = useState<any>(10); // Assuming type 'IState['data']'



  const [loading, setLoading] = useState<boolean>(false);
  const containerRef:any = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const result = await BrandController.GetPromoHeaderList(CollectionPromo.Promo);
      setData(result?.data?.promoCollections);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }, [promoCollections]);

  useEffect(() => {
    const checkScroll = () => {
      if (containerRef.current && promoCollections) {
        const container:any = containerRef.current as HTMLElement;
        const items:any = container.querySelectorAll('.P-link'); // Use the selector for your item class
  
        if (items.length > 0) {
          let accumulatedWidth = 0;
          let slicedDataCount = 0;
          // Measure the `hourImage` width

          const hourImage = container.querySelector('.hourImage');
          if (hourImage) {
            accumulatedWidth += hourImage.getBoundingClientRect().width;
          }
  
          // Measure the `viewAllLink` button width
          const viewAllLink = container.querySelector('.viewAllLink');
          if (viewAllLink) {
            accumulatedWidth += viewAllLink.getBoundingClientRect().width;
          }

          const DiscountLink = container.querySelector('.discountLink');
          if (DiscountLink) {
            accumulatedWidth += DiscountLink.getBoundingClientRect().width;
          }
  
          // Iterate over promoCollections and accumulate widths
          for (let i = 0; i < promoCollections.length; i++) {
            const item = items[i];
            if (!item) break; // Stop if we run out of DOM elements
            const itemWidth = item.getBoundingClientRect().width;
            const checkWidth = accumulatedWidth + itemWidth

            if (checkWidth < container.clientWidth && accumulatedWidth <= container.clientWidth) {
              slicedDataCount++
            } else {
              container.style.width = `100%`;
              setShowViewAll(true); 
              break;
            }
              
            accumulatedWidth += itemWidth;
          }
  
          if (window.innerWidth > 700) {
            setPromoListHeader(slicedDataCount); 
          }
        }
      }
    };
  
    checkScroll();
  }, [promoCollections]); // Ensure promoCollections is a stable dependency

  useEffect(() => {
    fetchData();
    const query = new URLSearchParams(window.location.search);
    const collectionId = query.get('CollectionId');
    setCollectionIdr(collectionId)
  }, []);

  const changeRouteDiscount = () => {
    const newUrl = `${ROUTES.PRODUCTS.MAIN}?hasDiscount=true&sortBy=${ProductSortEnum.PriceHighToLow}&page=1`;
    window.location.href = newUrl;
  };

  const changeRoute = (id: number) => {
    const newUrl = `${ROUTES.PRODUCTS.MAIN}?CollectionId=${id}&sortBy=1&page=1`;
    window.location.href = newUrl;
  };
               
  
  if(!promoCollections)return <ShimmerLoading colCount={1} childColCount={1} MClass={'headerLinkMainShimmer'} className={"productShimmer"}/> 
  return (
    <div className='headerLinkMain'>
        <div ref={containerRef} className={`P-header-link-natali`}>
          <span className='hourImage'><Hour24Img/></span>
          <div className={`discountLink`} onClick={() => changeRouteDiscount()}>
            <span><DiscountImg/></span>
            {Settings.translations.Discounts}
          </div> 

          {promoCollections?.slice(0,promoListHeader)?.map(({title,id}:any) => {
            return (
              <div key={id} 
                className={`P-link ${Number(CollectionId) === id ? 'activePromo' : ''}`}
                onClick={() => changeRoute(id)}
              >{title}</div> 
            )
          })}

          {showViewAll && <button className={`viewAllLink`}
            onClick={(e) => setIsOpen(!isOpen)}
          >{Settings.translations.View_All}</button> }
        </div>

        {isOpen && <PopupHeader items={promoCollections} onClose={(e:any) => {
          if (!(e.target as HTMLElement).classList.contains("viewAllLink")) {
            setIsOpen(false);
          }
        }}/>}
    </div>
    
  );
};

export default HeaderPromoLink;