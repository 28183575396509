import UserAddressController from "platform/api/userAddress";




export function validateFormCreate(form: any, submited?: boolean) {
  let valid = true;
  
  const errors = {
    building: false,
    addressText: false,
  };

  if (submited && !form.building.trim()) {
    errors.building = true;
    valid = false;
  }

  if (submited && (!form.addressText)) {
    errors.addressText = true;
    valid = false;
  }
      
  return {
    errors,
    valid,
  };
}

export function isCreate(form: any) {
  let valid = true;
  if (!form.building.trim() || !form.addressText) {
    valid = false;
  }
        
  return {
    valid,
  };
}



export const getPlacesFromYandex = async (featureMember:any) => {

  try {
    const resultPlaces = featureMember.map((feature:any) => {
      const geoObject = feature.GeoObject;
      const address = geoObject.metaDataProperty.GeocoderMetaData.Address;
      const coordinates = geoObject.Point.pos.split(' ');

      return {
        lat: parseFloat(coordinates[1]),
        lng: parseFloat(coordinates[0]),
        text: address.formatted,
        country: address.Components.find((x:any) => x.kind === 'country')?.name || null,
        city: address.Components.find((x:any) => x.kind === 'locality')?.name || address.Components.find((x:any) => x.kind === 'province')?.name || null,
        street: address.Components.find((x:any) => x.kind === 'street')?.name || address.Components.find((x:any) => x.kind === 'district')?.name || address.Components.find((x:any) => x.kind === 'vegetation')?.name || null,
        house: address.Components.find((x:any) => x.kind === 'house')?.name || null,
      };
    });

    return resultPlaces[0];
  } catch (error) {
    console.error('Error fetching data from Yandex:', error);
    throw error;
  }
};


export const getCoordinates = async (form:any,LatLng?:any) => {

  try {
    const resultPlaces = await UserAddressController.GetPlacesLatLngtYandex(LatLng,form?.addressText);
    const featureMember = resultPlaces.response.GeoObjectCollection.featureMember
    const coordinates = await getPlacesFromYandex(featureMember)

    return coordinates
  } catch (error) {
    console.error('Error fetching data from Yandex:', error);
    throw error;
  }
};