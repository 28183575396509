import Connection from '../../services/connection';
import { IResponse } from '../../constants/interfaces';
import { IUserAddressListResponseModel, IUserAddressDetailsResponseModel } from './models/response';
import { IUserAddressModifyRequestModel } from './models/request';

class UserAddressController {

  private static controller = 'userAddress';


  public static GetPlacesLatLngtYandex = async (LatLng:any,cityName: any) => {
    const apiKey = 'ebc3e314-c6d0-4b01-bf80-8c94524707d8';
    const baseUrl = 'https://geocode-maps.yandex.ru/1.x/';

    function getSearchingUrl(NewLatLng:any) {
      if(NewLatLng) {  
        const lat = NewLatLng[1]
        const lng = NewLatLng[0]
        return `${baseUrl}?apikey=${apiKey}&format=json&lang=ru_RU&geocode=${lat},${lng}`
      } else {
        return `${baseUrl}?apikey=${apiKey}&format=json&lang=ru_RU&geocode=${encodeURIComponent(cityName)}`
      }
    }
    
    const url = getSearchingUrl(LatLng);

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching data from Yandex:', error);
      throw error;
    }
  };

  public static GetList = (): Promise<IResponse<IUserAddressListResponseModel[]>> => {
    const result = Connection.GET({
      action: '',
      controller: UserAddressController.controller,
    });

    return result;
  };

  
  public static GetDefault = (): any => {
    const result = Connection.GET({
      action: 'GetDefault',
      controller: UserAddressController.controller,
    });

    return result;
  };

  public static GetDetails = (id: number): Promise<IResponse<IUserAddressDetailsResponseModel>> => {
    const result = Connection.GET({
      action: `${id}`,
      controller: UserAddressController.controller,
    });

    return result;
  };

  
  // action: 'CreateAddress'
  public static Create = (body: IUserAddressModifyRequestModel): Promise<IResponse<number>> => {
    const result = Connection.POST({
      body,
      action: '',
      controller: UserAddressController.controller,
    });

    return result;
  };

  public static Update = (id: number, body: IUserAddressModifyRequestModel): Promise<IResponse<boolean>> => {
    const result = Connection.PUT({
      body,
      action: `${id}`,
      controller: UserAddressController.controller,
    });

    return result;
  };

  public static Delete = (id: number): Promise<IResponse<boolean>> => {
    const result = Connection.DELETE({
      body: {},
      action: `${id}`,
      controller: UserAddressController.controller,
    });

    return result;
  };

  // action: `SelectAddress/${id}`
  public static MakeDefault = (id: number): Promise<IResponse<boolean>> => {
    const result = Connection.PUT({
      body: {},
      action: `Select/${id}`,
      controller: UserAddressController.controller,
    });

    return result;
  };
};

export default UserAddressController;