import * as React from 'react';
import { Link } from 'react-router-dom';

import { Shared } from 'modules';
import ROUTES from 'platform/constants/routes';
import { getMediaPath, formatPrice } from 'platform/services/helper';
import { IBasketListResponseModel } from 'platform/api/basket/models/response';

import './style.scss';
import { PromotionTypeEnum } from 'platform/constants/enums';
import Settings from 'platform/services/settings';

interface IProps {
  data: any[];
};

const List = React.memo(({ data }: IProps) => { 

  const [openChanged,setOpenChanges] = React.useState(false)



  function openChanges(e:any) {
    e.stopPropagation()
    e.preventDefault()

    setOpenChanges(!openChanged)
  }

  return (
    <div className="G-flex list_product">
      {data ? (data.length ? data.map((item) => {
        return <Link to={ROUTES.PRODUCTS.DETAILS.replace(':id', item.productId).replace(':slug', item.slug)}
          className="P-list-item"
          key={item.id}
        >
          {!!item.promotion.percent && <Shared.Products.DiscountLabel 
            percent={item.promotion.percent} 
            type={item.promotion.promotionType}
          />}

          <div className="P-image">
            <div className="P-image-parent">
              <div 
                className="G-square-image-block" 
                style={{ background: `url('${getMediaPath(item.productPhoto)}') center/contain no-repeat` }}
              />
            </div>
          </div>

          <div className='separateBasketrItem'>
            <div className='title_and_showChange desc_field'>
              <p className="P-prod-title">{item.productTitle}</p>
              {item?.isChanged && <button className="P-prod-isChanged" onClick={openChanges}>
                {!openChanged && Settings.translations.show_change}
                {openChanged &&  Settings.translations.hide_change}
              </button>}
            </div>

            <div className='title_and_showChange'>
              <div className="P-quantity">x {item.productQuantity}</div>
              {openChanged && <div className="P-quantity">x {item.initialCount}</div>}
            </div>

            <div className='title_and_showChange'>
              <div className="P-price">{formatPrice(item.discountedPrice)}</div>
              {openChanged && <div className="P-price">{formatPrice(item.initialAmount)}</div>}
            </div>
          </div>

        </Link>
      }) : null) : null}
    </div>
  );
});

export default List;
