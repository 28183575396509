import * as React from 'react';

import HelperComponent from 'platform/classes/helper-component';
import { byPrivateRoute } from 'platform/decorators/routes';
import ROUTES from 'platform/constants/routes';
import Layout from '../../../../components/layout';
import generic from 'platform/decorators/generic';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import OrderController from 'platform/api/order';
import { IOrderDetailsResponseModel } from 'platform/api/order/models/response';
import PageLoader from 'components/page-loader';
import Settings from 'platform/services/settings';
import { formatDateAsreceive, formatPrice, getViewEnum } from 'platform/services/helper';
import { PaymentTypeEnum } from 'platform/constants/enums';
import { OrderStatusEnum } from 'platform/api/order/constants/enums';
import { statusColorClassNamesNew } from '../../constants';
import List from '../components/list';
import './style.scss';
import ConfirmModal from 'components/confirm-modal';
import { MdOutlineArrowBack } from 'react-icons/md';
import addressIcon from "/src/assets/images/icons/address.svg"
import myprofileIcon from "/src/assets/images/my_profile.svg"
import calendarIcon from "/src/assets/images/icons/ic_date.svg"
import phoneIcon from "/src/assets/images/icons/ic_phone.svg"
import paymentIcon from "/src/assets/images/icons/ic_payment.svg"
import commentIcon from "/src/assets/images/icons/ic_comment.svg"
import { PaymentTypeEnumItems } from 'modules/pages/checkout/constants';
import IDramImage from 'assets/images/icons/idram.svg';



interface IState {
  data?: IOrderDetailsResponseModel;
  cancelConfirmOpen: boolean;
};

interface IRouteParams {
  id: string;
};

@generic<RouteComponentProps<IRouteParams>>(withRouter)
@byPrivateRoute([ROUTES.PROFILE.ORDERS.DETAILS,ROUTES.PROFILE.ORDERS.DETAILS_IDRAM])
class Details extends HelperComponent<RouteComponentProps<IRouteParams>, IState> {

  public state: any = {
    cancelConfirmOpen: false
  };

  public componentDidMount() {
    this.fetchData();
  }

  private statusViewEnum = getViewEnum(OrderStatusEnum);
  private paymentViewEnum = getViewEnum(PaymentTypeEnum);

  private fetchData = async () => {
    const { id } = this.props.match.params;
    const queryParams = new URLSearchParams(window.location.search);
    const edpBillNo = queryParams.get('EDP_BILL_NO');
    const orderId = Number(id || edpBillNo)
    const result = await OrderController.GetDetails(orderId);
    this.safeSetState({ data: result.data });
  };

  private repeatOrder = async () => {
    const { data } = this.state;
    
    if (data) {
      const result = await OrderController.Repeat(data.id);
      result.data && window.routerHistory.push(ROUTES.CART);
    }
  };

  private toggleConfirm = () => {
    const { cancelConfirmOpen } = this.state;
    this.safeSetState({ cancelConfirmOpen: !cancelConfirmOpen });
  };

  private cancel = async () => {
    const { data } = this.state;
    const alertify = await import('alertifyjs');

    if (data) {
      const res = await OrderController.Cancel(data?.id);
      if (res && res.data) {
        alertify.success(`
          <div class="MuiAlert_standardError_child">
              <strong>Success</strong>
              ${Settings.translations.order_canceled}
          </div>
        `);
        this.toggleConfirm();
        this.fetchData();
      } else {
        alertify.error(`
          <div class="MuiAlert_standardError_child">
            <strong>Error</strong>
            ${res.message}
          </div>
        `);
      };
    }
  };

  public render() {
    const { data, cancelConfirmOpen } = this.state;

    return (
      <Layout>
        {data ? <div className="P-profile-order-details">
          <Link to={ROUTES.PROFILE.ORDERS.MAIN}  className='title_cont_orders'>
            <MdOutlineArrowBack size={28}/>
            <p className='itemTitle'>{Settings.translations.back_orders}</p>
          </Link>
          <div className="P-info-wraper">
            <div className='newOrderTopSide'>
              <div className='id_status_field'>
                <span className="data_id">#{data.id}</span>
                <span>{formatDateAsreceive(data.createdDate,Settings.language)}</span>
                <span id="status" className={statusColorClassNamesNew[data.status]}>{Settings.translations[this.statusViewEnum[data.status]]}</span>
              </div>

              <div className='date_file'>
                <p className="P-order-date">
                  <img src={addressIcon}/>
                  <span>{data.addressText}</span>
                </p>

                <p className="P-order-date">
                  <img src={myprofileIcon}/>
                  <span>{data.fullName}</span>
                </p>

                <p className="P-order-date">
                  <img src={calendarIcon}/>
                  <span>{data.deliveryDate ? formatDateAsreceive(data.deliveryDate,Settings.language) : Settings.translations.as_soon_as_possible}</span>
                </p>
                
                <p className="P-order-date">
                  <img src={phoneIcon}/>
                  <span>{data.phone}</span>
                </p>

                <p className="P-order-date">
                  <img src={paymentIcon}/>
                  <span>{Settings.translations[this.paymentViewEnum[data?.paymentType]]}</span>
                </p>

                <p className="P-order-date">
                  <img src={commentIcon}/>
                  <span>{data.comment || '-'}</span>
                </p>
              </div>
            </div>

            <h3 className='titleOrder'>{Settings.translations.products} {data.baskets.length}</h3>
            <div className='newOrderBottomSide'>
              <List data={data.baskets}/>
              <div className='rightSide_container'>
                <div className="P-cart-right-box">
                  <div className="P-data-block-wrapper">
                    <div className="P-data-block G-flex-column">
                    <div className="collected_bonus G-flex G-flex-justify-between G-flex-align-center G-mb-25">
                      <span className="G-fs-normal G-clr-main G-text-bold">{Settings.translations.accumulative_bonus}</span>
                      <h1 className="G-clr-main G-text-bold G-fs-normal G-mt-5">{data.totalBonus} {Settings.translations.pointss}</h1>
                    </div>

                    <div className="total_basket G-flex G-flex-justify-between G-flex-align-center">
                        <p className="G-fs-normal G-text-bold">{Settings.translations.subtotal}</p>
                        <div className="G-flex G-flex-column G-align-center G-justify-center P-discounted-item">
                            <p className="G-text-bold G-fs-normal G-mt-5">{formatPrice(data.subtotal)}</p>
                        </div>
                    </div>

                    <div className="total_basket G-flex G-flex-justify-between G-flex-align-center">
                        <p className="G-fs-normal G-text-bold">{Settings.translations.delivery}</p>
                        <div className="G-flex G-flex-column G-align-center G-justify-center P-discounted-item">
                            <p className="G-text-bold G-fs-normal G-mt-5">{formatPrice(data.deliveryFee)}</p>
                        </div>
                    </div>

                    {!!data.tipToDriverAmount && 
                    <div className="total_basket G-flex G-flex-justify-between G-flex-align-center">
                        <p className="G-fs-normal G-text-bold">{Settings.translations.Tiptodriver}</p>
                        <div className="G-flex G-flex-column G-align-center G-justify-center P-discounted-item">
                          <p className="G-text-bold G-fs-normal G-mt-5">{formatPrice(data.tipToDriverAmount)}</p>
                        </div>
                    </div>}

                    {!!data.usedBonus && 
                    <div className="total_basket G-flex G-flex-justify-between G-flex-align-center">
                      <p className="G-fs-normal G-text-bold">{Settings.translations.used_bonus}</p>
                      <div className="G-flex G-flex-column G-align-center G-justify-center P-discounted-item">
                        <p className="G-text-bold G-fs-normal G-mt-5">{formatPrice(data.usedBonus)}</p>
                      </div>
                    </div>}

                    <div className="total_basket totalMargin G-flex G-flex-justify-between G-flex-align-center">
                        <h1 className="G-fs-normal G-text-bold">{Settings.translations.total}</h1>
                        <div className="G-flex G-flex-column G-align-center G-justify-center P-discounted-item">
                            <h1 className="G-text-bold G-fs-normal G-mt-5">{formatPrice(data.totalDiscountedPrice)}</h1>
                        </div>
                    </div>

                    {/* {data.status === OrderStatusEnum.Pending ? 
                    <button id={'cancel_btn'} className="conf_but" onClick={this.toggleConfirm}>
                      {Settings.translations.cancel}
                    </button> : 

                    <button id={'buy_again_btn'} className="conf_but" onClick={this.repeatOrder}>
                      {Settings.translations.buy_again}
                    </button>} */}

                    <button id={'buy_again_btn'} className="conf_but" onClick={this.repeatOrder}>
                      {Settings.translations.buy_again}
                    </button>
                  </div> 
              </div>


                
                </div>
                  <div className="P-cart-right-box">
                  <div className="P-data-block-wrapper">
                      <h1 className="title">{Settings.translations.Payment_info_title}</h1>
                      
                      <div className="total_basketNew G-flex G-flex-justify-between G-flex-align-center">
                          <p className="G-fs-normal G-text-bold">{Settings.translations.paid_with_bonuses}</p>
                          <div className="G-flex G-flex-column G-align-center G-justify-center P-discounted-item">
                              <p className="G-text-bold G-fs-normal G-mt-5">{data?.paymentData?.paidWithBonus}</p>
                          </div>
                      </div>

                      <div className="total_basketNew G-flex G-flex-justify-between G-flex-align-center">
                        <p className="G-fs-normal G-text-bold">
                          {Settings.translations.Purchased_from}
                          {PaymentTypeEnumItems().find((item:any) => item.type === data?.paymentType)?.name}
                          <img 
                            className={`image ${PaymentTypeEnum.Idram ? 'idramIcon' : ''}`} 
                            src={PaymentTypeEnumItems().find((item:any) => item.type === data?.paymentType)?.image || ''} 
                            alt='CashImage'
                          />
                        </p>
                        <div className="G-flex G-flex-column G-align-center G-justify-center P-discounted-item">
                            <p className="G-text-bold G-fs-normal G-mt-5">{formatPrice(data?.paymentData?.paid)}</p>
                        </div>
                      </div>

                      <div className="total_basketNew totalNew G-flex G-flex-justify-between G-flex-align-center">
                          <p className="G-fs-normal G-text-bold">{Settings.translations.total}</p>
                          <div className="G-flex G-flex-column G-align-center G-justify-center P-discounted-item">
                              <p className="G-text-bold G-fs-normal G-mt-5">{formatPrice(data?.paymentData?.totalPaid)}</p>
                          </div>
                      </div>

                      {!!data?.paymentData?.sentBackBonus && <div className="simple_row_order_detail return_bonus">
                        <div className='walltet_type'>
                          <span className="G-text-bold">{Settings.translations.Returned_bonus_amount}</span>
                          <span className="G-text-bold returned_bonus_amount">+ {formatPrice(data?.paymentData?.sentBackBonus)}</span>
                        </div>
                        <span className="G-text-bold">{Settings.translations.bonus_amount_send_back}</span>
                      </div>}
                    </div>
                  </div>
                </div>
            </div>
          </div>



          {cancelConfirmOpen && <ConfirmModal 
            title={Settings.translations.cancel}
            text={Settings.translations.are_you_sure_cancel_order}
            onConfirm={this.cancel}
            onClose={this.toggleConfirm}
            // {...confirmProps} 
          />}
        </div> : <PageLoader/>}
      </Layout>
    );
  }
}

export default Details;
