import * as React from 'react';
import HelperComponent from 'platform/classes/helper-component';
import { PharmacyType, AddressType } from 'platform/api/pharmacyBranch/models/response';
import './style.scss';
import imgUrl from "../../../../../assets/images/Natali-Pharm1.jpg"
import { SVGComponentCalendar, SVGComponentLocation, SVGComponentPhone } from 'components/svg-components';
import { ResizerImgPharmacyStatic, ResizerImgSizeStatic } from 'platform/hooks/useImageSize';
import { RefObject } from 'react';

class OurPharmacyNewSearchResults extends HelperComponent<any, any> {
  private ScrollRef: RefObject<HTMLElement> = React.createRef();
  public componentDidMount() {
    this.scrollToActiveItem();
  }

  public componentDidUpdate(prevProps: any) {
    if (prevProps.currentID !== this.props.currentID) {
      this.scrollToActiveItem();
    }
  }

  private scrollToActiveItem() {
    const { pharmacy, currentID } = this.props;
    const currentIndex = pharmacy.findIndex((item: any) => item.id === currentID);
    if (this.ScrollRef && this.ScrollRef.current) {
      const children = Array.from(this.ScrollRef.current.children);
      const matchingIndex = children.find((__,index) => index === currentIndex);
      matchingIndex?.scrollIntoView({ block: 'nearest' });
    }
  }

  public render() {
    const { pharmacy, open, callBack, currentID } = this.props;

    return (
      <section ref={this.ScrollRef} className={`P-pharmacies-search-results ${open ? 'openMap' : ''}`}>
        {pharmacy.map(({ id, cityName, address, addressLat, addressLng, phone, workingTime, photo }) => {
          const photoForMap = photo ? ResizerImgPharmacyStatic(photo) : imgUrl;
          return (
            <div 
              key={id} 
              className={`P-list ${currentID === id ? 'activeLocation' : ''}`} 
              onClick={() => callBack({ addressLat, addressLng, currentID: id })}
              role="button"
              tabIndex={0}
              aria-label={`Pharmacy in ${cityName}`}
            >
              <img 
                alt={`Pharmacy in ${cityName}`} 
                className='imgPharmacy' 
                src={photoForMap} 
                style={{ width: '50%', height: '100%' }} // Setting fixed dimensions
              />
              <div className='informationPharmacy'>
                <p className='title'>{cityName}</p>
                {address && 
                  <div className='desc_info address'>
                    <SVGComponentLocation aria-hidden="true"/>
                    <p className='titleDescInfo'>{address}</p>
                  </div>
                }
                {phone && 
                  <div className='desc_info phone'>
                    <SVGComponentPhone aria-hidden="true"/>
                    <p className='titleDescInfo'>{phone}</p>
                  </div>
                }
                {workingTime && 
                  <div className='desc_info workingTime'>
                    <SVGComponentCalendar aria-hidden="true"/>
                    <p className='titleDescInfo'>{workingTime}</p>
                  </div>
                }
              </div>
            </div>
          );
        })}
      </section>
    );
  }


};

export default OurPharmacyNewSearchResults;
