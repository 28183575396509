
import React, { useReducer, useState } from "react"
import Settings from "platform/services/settings"
import AutoComplate from "components/inputAutocomplate"
import { isCreate, validateFormCreate } from "./utils"
import UserAddressController from "platform/api/userAddress"
import MainButton from "components/Button/mainButton/mainButton"
import { addresOpenEnum } from "components/header"
import DispatcherChannels from "platform/constants/dispatcher-channels"
import { SVGComponentBackToIcon, SVGComponentCloseIcon } from "components/svg-components"
import "./style.scss"
import YandexMapComponent from "./mapsAddress"

enum formEnum {
    name = 1,
    addressText,
    building,
    entrance,
    apartment,
    floor
}

function reducer(state: any, action: any) {
    if (action.type === formEnum.building) {
        // Regex to allow only numbers, "4/7" format, or "4/"
        const validBuildingRegex = /^\d+(\/\d*)?$/;

        if (!validBuildingRegex.test(action.value) && action.value !== "") {
            return state; // Do nothing if input is invalid
        }
    }

    return {
        ...state,
        [formEnum[action.type]]:action.value
    };
}




export default function AddressCreateMainPopUp({onClose,HandleOpenAddress,addressData}) {
    const [submitLoading,setSubmitLoading] = useState(false)
    const [loadButton,setLoadButton] = useState(false)
    const [defaultCenter,setDefaultCenter] = useState([40.177586, 44.512651])
    const [isMapVisible, setMapVisibility] = useState(false);



    const [form,dispatch] = useReducer(reducer,{
        name:"",
        addressText:"",
        building:"",
        entrance:"",
        apartment:"",
        floor:""
    })

    async function submit(e: React.SyntheticEvent) {
        e.preventDefault();
        setSubmitLoading(true)
        setLoadButton(true)
        
        if(isCreate(form).valid) {
            const result = await UserAddressController.Create(form);
            const resultDefault = await UserAddressController.MakeDefault(result?.data);
            if(resultDefault.success) {
                window.dispatchEvent(new Event(DispatcherChannels.checkAddress))
                window.dispatchEvent(new CustomEvent(DispatcherChannels.CartUpdate));
                window.dispatchEvent(new CustomEvent(DispatcherChannels.productDetailUpdate));
                onClose()
                setTimeout(() => {window.location.reload()},0)
            }
        } else {
            setLoadButton(false)
        }
    }


    function changeValue(value: any) {
        dispatch({ type: formEnum.addressText, value });
    
        const buildingNumber = value.match(/\d+/)?.[0];
        if (buildingNumber !== undefined) {
            dispatch({ type: formEnum.building, value: buildingNumber });
        }
    }
    
    function changeValueAddress(value: any) {
        dispatch({ type: formEnum.addressText, value:value?.text });
        dispatch({ type: formEnum.building, value:value?.house });
    }

    return (
        <div className="create_address_container">
            <div className={`CreateAddressPopUp ${isMapVisible ? 'hideInputs' : ''}`}>
                <div className="titleField">
                    {!!addressData?.length && <div className="backIcon" onClick={() => HandleOpenAddress(addresOpenEnum.change)}><SVGComponentBackToIcon/></div> }
                    <p className='itemTitle'>{Settings.translations.add_address}</p>
                    <button className="onClose" onClick={() => onClose()}>
                        <SVGComponentCloseIcon/>
                    </button>
                </div>

                <div className="G-main-form P-form-block">
                    <div className="P-address-form">
                        <div className="G-main-form-field">
                            <p className="G-input-top-label">{Settings.translations.nameAddress}</p>
                            <input
                                placeholder={`${Settings.translations.Write_input} ${Settings.translations.nameAddress.toLowerCase()}`}
                                name="name"
                                value={form.name}
                                className={`G-main-input`}
                                onChange={(e) => dispatch({type:formEnum.name,value:e.target.value})}
                            />
                        </div>
                        <div className="G-main-form-field">
                            <AutoComplate
                                openMap={() => setMapVisibility(!isMapVisible)}
                                onPlaceSelect={(value:any) => changeValue(value)}
                                addressError={validateFormCreate(form,submitLoading).errors.addressText}
                                defaultValue={form.addressText}
                                fieldTitle={Settings.translations.address + ` *`}
                            />
                        </div>
                    </div>

                    <div className="addrerss_newMain">
                        <div className="G-main-form-field ">
                            <p className="G-input-top-label">{Settings.translations.building + ` *`}</p>
                            <input
                                // type="number"
                                placeholder={`${Settings.translations.Write_input} ${Settings.translations.building.toLowerCase()}`}
                                name="building"
                                value={form.building || ''}
                                className={`G-main-input ${validateFormCreate(form,submitLoading).errors.building ? 'G-invalid-field' : ''}`}
                                onChange={(e:any) => dispatch({type:formEnum.building,value:e.target.value})}
                            />
                        </div>
                        <div className="G-main-form-field">
                            <p className="G-input-top-label">{Settings.translations.entrance}</p>
                            <input
                                placeholder={`${Settings.translations.Write_input} ${Settings.translations.entrance.toLowerCase()}`}
                                name="entrance"
                                type="number"
                                value={form.entrance || ''}
                                className="G-main-input"
                                onChange={(e:any) => dispatch({type:formEnum.entrance,value:e.target.value})}
                            />
                        </div>
                        <div className="G-main-form-field">
                            <p className="G-input-top-label">{Settings.translations.floor}</p>
                            <input
                                placeholder={`${Settings.translations.Write_input} ${Settings.translations.floor.toLowerCase()}`}
                                name="floor"
                                type="number"
                                value={form.floor || ''}
                                className="G-main-input"
                                onChange={(e:any) => dispatch({type:formEnum.floor,value:e.target.value})}
                            />
                        </div>
                        <div className="G-main-form-field">
                            <p className="G-input-top-label">{Settings.translations.apartment}</p>
                            <input
                                placeholder={`${Settings.translations.Write_input} ${Settings.translations.apartment.toLowerCase()}`}
                                name="apartment"
                                value={form.apartment || ''}
                                className="G-main-input"
                                onChange={(e:any) => dispatch({type:formEnum.apartment,value:e.target.value})}
                            />
                        </div>
                    </div>
                    
                    <div className="buttonFieldCreateMain">
                        
                        <div className="buttonField">
                            <MainButton text={Settings.translations.cancel} onClick={onClose} />
                            <MainButton loading={loadButton} text={Settings.translations.add_address_button} onClick={submit} />
                        </div>
                    </div>
                </div>
            </div>

            <div className={`rightSide_map ${isMapVisible ? 'showMobileMap' : ''}`}>
                <div className="mobile_addrerss_text">
                    <p className="addressText">{form.addressText}</p>
                    <button 
                        className="choose_mobile_address"
                        onClick={() => setMapVisibility(!isMapVisible)}
                    >
                        {Settings.translations.choose_address}
                    </button>
                </div>
                <YandexMapComponent 
                    defaultCenter={defaultCenter}
                    callBack={changeValueAddress}
                />
            </div>

        </div>
    )
}